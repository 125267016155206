<template>
    <div>
        <Header />
        <div style="background-color: #c49b6c !important">
            <!-- <router-link :to="{path:'/search'}">
          <div class="search">
              <div style="width: 20px;height: 20px; margin-right: 4px; float:left;">
                  <img src="../../assets/index/search_icon.png" style="width: 100%;height: 100%;">
              </div>
              <div style="width: 40px;height: 20px;float:left;margin-top: -4px;">搜索</div>
          </div>
        </router-link> -->
            <div v-loading="banner_loading">
                <el-carousel
                    trigger="click"
                    :style="{
                        height: port && port == 'phone' ? '105px' : '100%',
                        overflow: 'hidden',
                    }"
                    :interval="3000"
                    arrow="always"
                    ref="carousel"
                >
                    <el-carousel-item
                        v-for="item in imgList"
                        :key="item"
                        @click="banner(item.type, item.url)"
                    >
                        <img
                            :src="item.src"
                            :style="{
                                width: '100%',
                                height:
                                    port && port == 'phone' ? '105px' : '100%',
                            }"
                        />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div
                style="overflow: hidden"
                class="hello-title-con quick-link-con"
            >
                <div style="white-space: nowrap; padding-right: 5px">
                    <router-link :to="{ path: '/share' }">
                        <div
                            hover-class="round-btn--hover"
                            style="display: inline; padding: 5px 7px"
                        >
                            <span style="text-align: center">{{ share }}</span>
                        </div>
                    </router-link>
                    <router-link :to="{ path: '/task' }">
                        <div
                            hover-class="round-btn--hover"
                            style="display: inline; padding: 5px 7px"
                        >
                            <span style="text-align: center">{{
                                punch_card
                            }}</span>
                        </div>
                    </router-link>
                    <!-- <router-link :to="{path:'/task/grade_table',query:{
              type:'home_page'
            }}">
              <div hover-class="round-btn--hover" style="display: inline; padding: 5px 7px;">
                <span data-v-08516df3="" style="text-align: center;">成績表</span>
              </div>
            </router-link> -->
                </div>
            </div>
            <div class="text">
                <div
                    v-for="items in text_imgList"
                    :key="items"
                    :class="{
                        text_div: !port || port == 'phone',
                        text_divs: port == 'pc',
                    }"
                >
                    <!-- <router-link v-if="items.id == 2 || items.id == 4 || items.id == 6 || items.id == 7 || items.id == 8 || items.id == 9" :to="{path:'/training_schedule',query:{type:items.id}}">
              <img :src="items.src"  style="width:100%;height:100%;" />
            </router-link>
            <router-link v-else-if="items.id == 3" :to="{path:'/content',query:{sort_id:3}}">
              <img :src="items.src"  style="width:100%;height:100%;" />
            </router-link>-->
                    <router-link
                        :to="{
                            path: '/training_schedule',
                            query: { type: items.id },
                        }"
                    >
                        <img
                            :src="items.image"
                            style="width: 100%; height: 100%"
                        />
                    </router-link>
                </div>
            </div>
            <div style="width: 100%; height: 90px"></div>
            <div style="width: 100%; height: 90px"></div>
        </div>
        <Footer />
    </div>
</template>

<style>
/* //修改loading加载时的颜色 */
.el-loading-spinner .path {
    stroke: rgb(203, 167, 126);
}
.text_div {
    width: 100%;
    height: 7rem;
}
.text_divs {
    width: 100%;
    height: 30vh;
}
.img {
    display: unset;
    vertical-align: unset;
}
.quick-link-con {
    overflow-x: scroll;
    padding: 5px 10px;
    border-top: 0 solid #fff;
    border-bottom: 1px solid #fff;
    height: 30px !important;
    /* line-height: 30px; */
    font-size: 18px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.search {
    margin: 8px 0;
    padding-left: 10px;
    height: 4vh;
    border: 1px solid #fff;
    border-radius: 12px;
    color: #fff;
    line-height: 5.5vh;
    margin-top: 8vh;
}
</style>
<script>
import Header from "../Header";
import Footer from "../Footer";
import { apiBanner, apiHome } from "@/request/api";
export default {
    components: { Header, Footer },
    data() {
        return {
            times: 3,
            banner_loading: false,
            imgList: [],
            text_imgList: [],
            share: "",
            punch_card: "",
            // 判断当前是手机端还是pc
            port: "",
        };
    },
    created() {
        let lang_id = this.$store.state.lang_id;
        this.port = this.$store.state.port;

        if (lang_id == 1) {
            this.share = this.$zh_ft("share");
            this.punch_card = this.$zh_ft("punch_card");
        } else {
            this.share = this.$zh_jt("share");
            this.punch_card = this.$zh_jt("punch_card");
        }
        apiHome
            .then((res) => {
                this.text_imgList = res.data;
            })
            .catch((error) => {
                console.log(error);
            });

        this.banner_loading = true;
        apiBanner({
            lang_id: this.$store.state.lang_id,
        })
            .then((res) => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            this.imgList.push({
                                src: res.data.data[i].image,
                                type: "",
                                url: res.data.data[i].link,
                            });
                        }
                    }
                }
                let timer = setInterval(() => {
                    this.times--;
                    if (this.times === 0) {
                        this.banner_loading = false;
                        clearInterval(timer);
                    }
                }, 1000);
            })
            .catch((error) => {
                console.log(error);
                let timer = setInterval(() => {
                    this.times--;
                    if (this.times === 0) {
                        this.banner_loading = false;
                        clearInterval(timer);
                    }
                }, 1000);
            });
    },
    methods: {
        banner(val, url) {
            if (url) {
                location.href = url;
                return false;
            }
        },
    },
};
</script>

